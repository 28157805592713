import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-square',
  templateUrl: './square.component.html',
  styleUrls: ['./square.component.scss']
})
export class SquareComponent implements OnInit {
  
  @Input() value:'X' | 'O' | undefined;

  Rando:number=0;
  constructor() {
    //setInterval(() =>this.Rando =Math.random(),1000);
   }

  ngOnInit(): void {
  }

}
