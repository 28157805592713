<h1>Corrent player:{{Player}}</h1>
<button (click)="newGame()" class="text-center" style="margin-top: 5px;margin-bottom: 5px;margin-left: 50%;">Start new game</button>

<h2 *ngIf="winner"style="margin-top: 5px;margin-bottom: 5px;margin-left: 50%;"> 
    Player {{winner}} won the game!!
</h2>

<main style="margin-top: 5px;margin-bottom: 5px;margin-left: auto;margin-right: auto;">
    <app-square  *ngFor="let val of squares; let i = index"
    [value]="val"
    (click) = "makeMove(i)">

    </app-square>
</main>